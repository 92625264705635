import React from "react";
import cx from "clsx";
const sizeClassName = {
  small: "px-[35px] py-[12px]",
  medium: "px-[24px] py-[8px]",
};
const colorClassName = {
  black: cx(
    "bg-black text-white border-black",
    "hover:bg-white hover:border-black hover:text-black"
  ),
};

const Button = (props) => {
  const { children, size = "medium", color, className, onClick, fontSize = 14, buttonStyle = {} } = props;

  return (
    <div
      onClick={onClick}
      className={cx(
        className,
        "inline-block border-[1px] transition-tra border-primary] cursor-pointer text-[14px] md:text-[1rem] leading-4 w-fit font-medium outline-none text-primary border-primary",
        sizeClassName[size],
        colorClassName[color],
        "hover:border-accent hover:text-accent",
        "transition-all duration-500"
      )}
      style={{fontSize: fontSize, ...buttonStyle}}
    >
      {children}
    </div>
  );
};

export default Button;
