import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import { getGoogleDriveDisplayURL } from "src/utils";

export default function ProjectItem({ item }) {
  // console.log("ProjectItem", item);
  const navigate = useNavigate();

  const image = item.galleries.find((el) => el.images.length > 0)?.images[0]
    ?.path;
  const handleProductClick = () => {
    navigate(ROUTES.Main.ProjectDetail + "/" + item.category + "/" + item.id);
  };
  return (
    <div
      className="group cursor-pointer relative aspect-[4/3] md:w-auto"
      onClick={handleProductClick}
    >
      <img
        src={getGoogleDriveDisplayURL(image)}
        className="w-full h-full object-cover"
        alt=""
      />
      <div className="opacity-0 group-hover:opacity-50 absolute bg-white top-0 bottom-0 left-0 right-0 boxHover flex justify-center items-center">
        <p className="text-primary uppercase mb-2 text-[18px] leading-5">
          Xem dự án
        </p>
      </div>
      <div className="absolute bottom-0 left-0 right-0 bg-white opacity-0 group-hover:opacity-70">
        <p className="text-black uppercase text-[14px] leading-5 font-medium p-2">
          {item.name}
        </p>
      </div>
    </div>
  );
}
