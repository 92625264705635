import React, { useEffect, useRef, useState } from "react";

const ScrollAnimation = (props) => {
  const { animateIn, animateOut, children, disableAnimation } = props;

  const myRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      const isVisible = entry.isIntersecting;
      if ((isVisible && animateIn) || (!isVisible && animateOut)) {
        setIsVisible(isVisible);
      }
    });
    if (!disableAnimation) {
      observer.observe(myRef.current);
    }
  }, []);

  return (
    <div ref={myRef} className={isVisible ? animateIn : ""}>
      {children}
    </div>
  );
};

export default ScrollAnimation;
