import React, { useEffect, useState } from "react";
import ScrollAnimation from "src/components/ScrollAnimation";
import GalleryItem from "../Projects/shared/ProjectItem";
import ContactUs from "./shared/ContactUs";
import Gallery from "./components/Gallery";
import { useParams } from "react-router-dom";
import { useLoading } from "src/hooks/useLoading";
import useFirebase from "src/hooks/useFirebase";
import { getGoogleDriveDisplayURL } from "src/utils";
import styles from "./components/Gallery/gallery.module.scss";
import useWindowDimensions from "src/hooks/useWindowDimensions";

export default function ProjectDetail() {
  const { id, category } = useParams();
  const { width } = useWindowDimensions();

  const [projects, setProjects] = useState([]);
  const loading = useLoading();
  const { getProjects } = useFirebase();

  const details = projects?.find((el) => el.id === id) ?? {};
  const galleries = details.galleries ?? [];

  useEffect(() => {
    async function fetchData() {
      localStorage.setItem("@category", category);
      loading.showLoading();
      const data = await getProjects(category);
      setProjects(data.filter((el) => el.hidden !== true));
      setTimeout(() => {
        loading.hideLoading();
      }, 500);
    }
    category && fetchData();
  }, [category]);

  return (
    <div>
      {/* text section */}
      <div className="flex flex-col items-center m-[10px] md:m-[20px] my-[30px]">
        <div className="flex flex-col gap-[10px] md:gap-[10px]">
          <ScrollAnimation animateIn="animation-fadeIn">
            <h3
              className="text-primary text-[48px] md:text-[60px]"
              style={{ lineHeight: 1.25 }}
            >
              {details.name}
            </h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="mt-0 animate-bounceInRight">
            <div className="h-[1px] w-[200px] bg-black" />
          </ScrollAnimation>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {details.description?.length > 0 &&
              details.description?.map((description) => {
                return (
                  <p
                    className="text-primary w-full sub-text-size"
                    style={{ fontSize: parseInt(14) }}
                  >{`${description}`}</p>
                );
              })}
          </div>
        </div>
      </div>
      {/* gallery section */}
      {galleries.map((gallery, index) => (
        <div
          className="flex flex-col items-center justify-center pt-0 md:py-[30px] px-2"
          style={{ backgroundColor: index % 2 === 0 ? "white" : "white" }}
        >
          <div
            key={index.toString()}
            className="my-[20px] md:my-[50px] w-full max-w-[100%] md:max-w-[80%]"
          >
            <Gallery
              images={gallery.images.map((el) => ({
                ...el,
                originalClass: styles.galleryItem,
                original: getGoogleDriveDisplayURL(el.path),
                thumbnail: getGoogleDriveDisplayURL(el.path),
                thumbnailClass:
                  "w-[100px] md:w-[100px] lg:w-[150px] h-[100px] md:h-[100px] lg:h-[150px] ratio-square aspect-square",
              }))}
              key={index}
            />
          </div>
        </div>
      ))}

      <div
        className="py-[10px] pb-[40px]"
        style={{
          backgroundColor: galleries.length % 2 === 0 ? "#76817d" : "#76817d",
        }}
      >
        <div className="flex flex-col items-center m-[20px] my-[30px]">
          <div className="flex flex-col gap-[10px] md:gap-[10px]">
            <ScrollAnimation animateIn="animation-fadeIn">
              <h3
                className="text-primary text-[48px] md:text-[58px]"
                style={{ lineHeight: 1.25 }}
              >
                CÁC DỰ ÁN KHÁC
              </h3>
            </ScrollAnimation>
          </div>
        </div>
        {/* images section */}
        <div className="container px-2 lg:px-0 mx-auto grid grid-cols-2 justify-between md:grid-cols-4 gap-2 md:gap-[32px] mt-[50px]">
          {projects
            .filter((el) => el.id !== id)
            .map((el, index) => (
              <GalleryItem item={el} key={index} />
            ))}
        </div>
      </div>
      {/* contact us section*/}
      <ContactUs />
    </div>
  );
}
