import React, { useEffect, useState } from "react";
import Image1 from "./images/img1.jpg";
import Image2 from "./images/img2.jpg";
import Image3 from "./images/img3.jpg";
import Image4 from "./images/img4.jpg";
import ScrollAnimation from "src/components/ScrollAnimation";
import Button from "src/components/Button";
// import { useNavigate } from "react-router-dom";
import { useApp } from "src/hooks/useApp";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";

const OUTSTANDING_IMAGES = [Image1, Image2, Image3, Image4];

const Outstanding = (props) => {
  const {showModal} = useApp()
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % OUTSTANDING_IMAGES.length
      );
    }, 3000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const openAllProjectsPage = () => {
    showModal();
    // navigate('projects');
  }

  return (
    <div className="h-auto md:h-screen mt-[50px] md:mt-auto flex flex-col md:flex-row container mx-auto relative ">
      <div className="padding-x-box grid gap-[20px] mb-[20px] md:w-3/4 h-auto mt-0 md:mt-6 md:h-auto md:z-10 md:absolute">
        <h3 className="text-primary title-2 md:mt-[70px] md:text-[50px] text-[30px]" style={{ lineHeight: 1.5 }}>
          <ScrollAnimation disableAnimation animateIn="animation-fadeIn">
            Tầm nhìn của bạn
            <br /> Giá trị từ chúng tôi
          </ScrollAnimation>
        </h3>
        <ScrollAnimation disableAnimation animateIn=" md:mt-0 animate-slowBounceInLeft">
          <div className="h-[1px] w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="text-primary md:w-[50%] lg:w-2/3 sub-text-size text-[14px]">
          Chúng tôi mang đến cho bạn 3 giá trị thiết yếu: Giá trị thẩm mỹ, Giá
          trị công năng, Giá trị hoàn thiện thi công
          <br />
          Sau cùng cái chúng tôi hướng đến nhằm tao dựng nên một giá trị bền
          vững đó là giá trị về niểm tin
        </p>
        <Button onClick={() => navigate(ROUTES.Main.Projects)}>Xem thêm dự án</Button>
      </div>
      <div className="md:w-1/2 flex justify-end md:absolute right-0">
        <div className="relative">
          <img
            src={OUTSTANDING_IMAGES[currentIndex]}
            id="img"
            className="w-auto aspect-square md:h-[50vw] md:w-[50vw] object-cover"
            alt="..."
          />
          <div className="bg-white w-full h-full absolute top-0 bg-opacity-15"></div>
        </div>
      </div>
    </div>
  );
};

export default Outstanding;
