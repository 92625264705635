import React from "react";
import WelcomeImage from "./images/homepage_company.jpg";
import Button from "src/components/Button";
import ScrollAnimation from "src/components/ScrollAnimation";
import 'reactjs-popup/dist/index.css';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <div className="h-auto md:h-[100vh] flex flex-col md:flex-row items-center justify-center p-[20px] md:gap-[5vw] pb-[100px] md:pb-[0px]" style={{ position: 'relative' }}>
      <div className="w-[100vw] h-[100vw] md:h-[40vw] md:w-[40vw] p-[20px] pt-[0px] aspect-square	">
        <img src={WelcomeImage} className="object-cover w-full h-full" alt="" />
      </div>
      <div className="md:w-[40vw] flex flex-col gap-[10px] md:gap-[20px]">
        <ScrollAnimation disableAnimation animateIn="animation-fadeIn">
          <h3 className="text-primary text-[24px] md:text-[30px]">
            Hiểu rõ hơn về Hidiarch tại đây!
          </h3>
        </ScrollAnimation>
        <ScrollAnimation disableAnimation animateIn="mt-0 animate-bounceInRight">
          <div className="h-[1px] w-[200px] bg-black" />
        </ScrollAnimation>
        <p className="text-primary w-full sub-text-size" style={{ fontSize: parseInt(14) }}>
          Hidiarch ra đời nhằm mang đến những giá trị thẩm mỹ, tối ưu công năng
          cho không gian sống cũng như lan toả niềm đam mê, nhiệt huyết của tuổi
          trẻ, góp phần phát triển và vươn xa hơn cho nền kiến trúc Việt Nam.
        </p>
        <Button onClick={() => navigate(ROUTES.Main.AboutUs)}>Tìm hiểu thêm</Button>
      </div>
      {/* {renderPopup()} */}
    </div>
  );
};

export default Welcome;
